import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { TruckBrand } from './TruckBrand';
import { Assignment, AssignmentStore } from './Assignment';
import { ActivityStore } from './Activity';
import { Entity } from './Entity';
import { TruckLicenseStore } from './License';
import { ShellCardStore } from './ShellCard';
import { FormResponse } from './Form/Response';
import { TruckTollboxStore } from './TruckTollbox';
import { LeaseContractStore } from './LeaseContract';
import { InsuranceContractStore } from './InsuranceContract';
import { ServiceContractStore } from './ServiceContract';
import { TruckPosition, TruckPositionStore } from './TruckPosition';
import { TruckEquipmentStore } from './TruckEquipment';
import { DocumentStore } from './Document';
import { addInterval } from 'helpers/date';
import { omit } from 'lodash';
import moment from 'moment';
import { DATA_SOURCE_TRANSICS, DATA_SOURCE_MOBILE_OFFICE, DATA_SOURCE_FLEETBOARD } from './Base';
import { TruckStatusChangeStore, TruckStatusChange } from './StatusChange';
import { SERVER_DATETIME_FORMAT, MOBILITY_PACKAGE_START_DATE } from '../helpers';
import { User } from './User';

export const TYPE_STANDARD = 'standard';
export const TYPE_MEGA = 'mega';
export const TYPE_MEGA_PLUS = 'mega plus';
export const TYPE_BDF = 'bdf';
export const TYPES = [TYPE_STANDARD, TYPE_MEGA, TYPE_MEGA_PLUS, TYPE_BDF];

export const CERTIFICATION_TSR3 = 'tsr3';
export const CERTIFICATION_TSR2 = 'tsr2';
export const CERTIFICATION_TSR1 = 'tsr1';
export const CERTIFICATIONS = [CERTIFICATION_TSR1, CERTIFICATION_TSR2, CERTIFICATION_TSR3]

export const CATEGORY_TRUCK = 'truck';
export const CATEGORY_BUS = 'bus';
export const CATEGORY_PRIVATE_CAR = 'private car';
export const CATEGORIES = [CATEGORY_TRUCK, CATEGORY_BUS, CATEGORY_PRIVATE_CAR];

export const FUEL_TYPE_ON = 'on';
export const FUEL_TYPE_PB = 'pb';
export const FUEL_TYPE_EL = 'el';
export const FUEL_TYPE = [FUEL_TYPE_ON, FUEL_TYPE_PB, FUEL_TYPE_EL];
export const MARKED_COLORS = ['red', 'blue', 'yellow', 'violet', 'grey', 'black'];
export const TRUCK_COLOR = ['white', 'purple', 'red', 'blue', 'black', 'grey'];
export const FUEL_TANK_COUNTS = [1, 2];

const mobilityPackageStartDateMoment = moment(MOBILITY_PACKAGE_START_DATE);

export class Truck extends Model {
    static backendResourceName = 'truck';

    static types = TYPES; // Backwards compatibility.
    static euroClasses = [3, 4, 5, 6];

    @observable id = null;
    @observable licensePlate = '';
    @observable countryOfRegistration = '';
    @observable daysAllowedOutsideCountryOfRegistration = null;
    @observable type = null;
    @observable certification = CERTIFICATION_TSR3;
    @observable description = '';
    @observable dataSource = '';
    @observable dataReference = '';
    @observable looseEquipment = '';
    @observable fixedEquipment = '';
    @observable markedColors = [];
    @observable color = null;
    @observable plannedDueHomeDate = null;

    // Specs
    @observable weight = null;
    @observable euroClass = null;
    @observable customerSpecificGear = '';
    @observable dateOfConstruction = null;

    // Fuel consumption
    @observable fuelCode = '';
    @observable fuelCapacity = 0;
    @observable fuelLevelMinimum = 0; // In deci-litres
    @observable fuelConsumptionTarget = 0;
    @observable fuelConsumptionActual = 0;
    @observable lastStatusUpdate = null;

    @observable fuelTankCount = null;
    @observable currentFuelLevel = 0; // in deci-litres for fleetvisor trucks
    @observable currentIgnition = null;
    @observable currentOdometer = 0; // in km
    @observable currentSpeed = null;

    // Service dates
    @observable lastServiceDate = null;
    @observable lastServiceKm = null;
    @observable _nextServiceKm = null;
    // @observable plannedNextServiceDate = null;
    @observable nextServiceDescription = '';
    @observable tuvExpirationDate = null;
    @observable nextTachoRecalibrationDate = null;
    @observable conceptMessage = '';
    @observable fuelType = FUEL_TYPE_ON
    @observable category = CATEGORY_TRUCK;
    @observable deleted = false;
    @observable updatedBy = null;

    @observable costCenter = '';
    @observable startDate = null;
    @observable exitDate = null;
    @observable exitReason = '';
    @observable vinNumber = '';
    @observable serviceInterval = '';
    @observable serviceIntervalKm = '';
    @observable hasBrokenSatellite = '';
    @observable phoneNumber = '';

    @observable purchaseValue = null;

    @observable sendPositionUpdatesToFela = false;
    @observable _lastVisitCountryOfRegistrationTruckPosition = null;
    @observable _hasFetchedLastVisitCountryOfRegistrationTruckPosition = false;

    @computed get lastKnownVisitCountryOfRegistrationMoment() {
        return moment.max(
            mobilityPackageStartDateMoment,
            this._lastVisitCountryOfRegistrationTruckPosition ? this._lastVisitCountryOfRegistrationTruckPosition.measuredAt : mobilityPackageStartDateMoment,
            this.startDate || mobilityPackageStartDateMoment,
        ).startOf('day');
    }

    @computed get nextVisitCountryOfRegistrationMoment() {
        return this.lastKnownVisitCountryOfRegistrationMoment.clone().add(this.daysAllowedOutsideCountryOfRegistration, 'days');
    }

    daysLeftOutsideCountryOfRegistration(today) {
        return this.nextVisitCountryOfRegistrationMoment.diff(today, 'days');
    }

    purchaseValueDiff(tvmPurchaseValue) {
        if (tvmPurchaseValue !== this.purchaseValue / 100 && tvmPurchaseValue) {
            return true
        }
        return false
    }

    casts() {
        return {
            type: Casts.enum(this.constructor.types),
            plannedDueHomeDate: Casts.date,
            lastServiceDate: Casts.date,
            dateOfConstruction: Casts.date,
            // plannedNextServiceDate: Casts.date,
            startDate: Casts.date,
            exitDate: Casts.date,
            tuvExpirationDate: Casts.date,
            nextTachoRecalibrationDate: Casts.date,
            lastStatusUpdate: Casts.datetime,
        };
    }

    relations() {
        return {
            currentAssignment: Assignment,
            currentPosition: TruckPosition,
            currentFormResponse: FormResponse,
            leaseContracts: LeaseContractStore,
            insuranceContracts: InsuranceContractStore,
            serviceContracts: ServiceContractStore,
            statusChanges: TruckStatusChangeStore,
            brand: TruckBrand,
            currentStatus: TruckStatusChange,
            entity: Entity,
            assignments: AssignmentStore,
            truckLicenses: TruckLicenseStore,
            shellCards: ShellCardStore,
            tollboxes: TruckTollboxStore,
            equipments: TruckEquipmentStore,
            documents: DocumentStore,
            updatedBy: User,
        };
    }

    addTruckToTvmPolicy(startDate, insuranceContractId=null, financierNumber=null) {
        return this.wrapPendingRequestCount(this.api.post(this.url + 'add_truck_to_tvm_policy/', {
            start_date: startDate,
            insurance_contract_id: insuranceContractId,
            financier_number: financierNumber
        }))
    }

    deleteTruckFromTvmPolicy(endDate, insurance_contract_id) {
        return this.wrapPendingRequestCount(this.api.post(this.url + 'delete_truck_from_tvm_policy/', {
        end_date: endDate,
        insurance_contract_id: insurance_contract_id,
    }))}

    retrieveTvmTruckInfo() {return this.wrapPendingRequestCount(this.api.post(this.url + 'retrieve_tvm_truck_info/', {}))}

    updateTvmPolicy(licensePlateBeforeChange, insuranceContractId, financierNumber) {return  this.wrapPendingRequestCount(this.api.post(this.url + 'update_tvm_policy/', {
        license_plate_before_change: licensePlateBeforeChange,
        insurance_contract_id: insuranceContractId,
    }))}

    toBackend(...args) {
        const attrs = super.toBackend.apply(this, args);

        if (attrs.service_interval) {
            attrs.service_interval = attrs.service_interval.toUpperCase();
        }

        return omit(attrs, 'current_assignment');
    }

    toJS(...args) {
        const result = super.toJS.apply(this, args);

        // Unfortunately we have to remove manually... otherwise the error
        // "Converting circular structure to JSON" will be thrown.
        return omit(result, '_lastVisitCountryOfRegistrationTruckPosition', '_hasFetchedLastVisitCountryOfRegistrationTruckPosition');
    }

    markBrokenSatellite() {
        return this.api.post(this.url + 'broken_satellite/', {
            has_broken_satellite: true,
        }).then(() => {
            this.hasBrokenSatellite = true;
        });
    }

    markWorkingSatellite() {
        return this.api.post(this.url + 'broken_satellite/', {
            has_broken_satellite: false,
        }).then(() => {
            this.hasBrokenSatellite = false;
        });
    }

    lastServiceKmFetch(){
        if(this.lastServiceDate!=null){
            const truckPositionStore = new TruckPositionStore({
                limit: 1,
            });
            truckPositionStore.params = {
                '.truck': this.id,
                '.measured_at:lte': this.lastServiceDate && this.lastServiceDate.clone().add(1, 'days').format(SERVER_DATETIME_FORMAT),
                'order_by': '-measured_at',
                include_meta: false,
            };
            truckPositionStore.fetch()
            .then(()=>{
                if (truckPositionStore.length === 1) {
                    this.setInput('lastServiceKm', truckPositionStore.at(0).odometer)
                } else {
                    this.setInput('lastServiceKm', null)
                }
            })
        }
    }

    fetchFirstActivity() {
        return new Promise(resolve => {
            const activityStore = new ActivityStore();

            activityStore.params = {
                '.assignment.truck': this.id,
                'limit': 1,
                'order_by': 'ordered_arrival_datetime_from',
            };

            return activityStore.fetch().then(() => {
                if (activityStore.length === 1) {
                    resolve(activityStore.at(0));
                } else {
                    resolve(null);
                }
            });
        });
    }

    fetchLastVisitCountryOfRegistrationTruckPosition() {
        const truckPositionStore = new TruckPositionStore({
            params: {
                '.truck': this.id,
                '.closest_country': this.countryOfRegistration,
                order_by: '-measured_at',
                limit: 1,
                include_meta: false,
            },
        });

        return this.wrapPendingRequestCount(
            truckPositionStore.fetchBackground().then(() => {
                this._hasFetchedLastVisitCountryOfRegistrationTruckPosition = true;

                if (truckPositionStore.length === 1) {
                    this._lastVisitCountryOfRegistrationTruckPosition = truckPositionStore.at(0);
                    return this._lastVisitCountryOfRegistrationTruckPosition;
                }

                return null;
            })
        );
    }

    @computed
    get plannedNextServiceKm() {
        return parseInt(this.lastServiceKm) + parseInt(this.serviceIntervalKm)
    }

    @computed
    get plannedNextServiceDate() {
        return addInterval(this.lastServiceDate, this.serviceInterval);
    }

    @computed
    get earliestServiceDate() {
        const licenseExpiryDates = this.truckLicenses.map('dateOfExpiry');
        const dates = [
            this.plannedNextServiceDate,
            this.tuvExpirationDate,
            this.nextTachoRecalibrationDate,
            ...licenseExpiryDates,
        ].filter(Boolean);
        // moment.min() automatically uses the current date when you pass an empty array, wtf?
        if (dates.length > 0) {
            return moment.min(dates);
        }
        return null;
    }

    // Fuel levels are in deci-litres from fleetvisor
    @computed
    get currentFuelLevelInLiters() {
        // Suka kostyl'
        return [DATA_SOURCE_TRANSICS, DATA_SOURCE_MOBILE_OFFICE, DATA_SOURCE_FLEETBOARD].includes(this.dataSource) ? this.currentFuelLevel : Math.round(this.currentFuelLevel / 10);
    }

    @computed
    get drivingStatus() {
        if (this.currentIgnition === null) {
            return 'unknown';
        }
        if (this.currentIgnition === false) {
            return 'stopped';
        }
        if (this.currentIgnition === true) {
            if (this.currentSpeed && this.currentSpeed > 0) {
                return 'driving';
            }
            return 'paused';
        }

        return '';
    }

    @computed
    get needsRefueling() {
        if (this.fuelLevelMinimum) {
            return this.currentFuelLevel < this.fuelLevelMinimum;
        }

        return false;
    }
}

export class TruckStore extends Store {
    Model = Truck;
    static backendResourceName = 'truck';

    deleteManyTrucksFromTvmPolicy(trucks, endDate) {
        if (trucks) {
            return this.wrapPendingRequestCount(this.api.post('/truck/delete_many_trucks_from_tvm_policy/', {
                trucks: trucks,
                end_date: endDate
            }))
            .then(res => {
                return res;
            });
        }
    }

    tvmHealth() {
        return this.wrapPendingRequestCount(this.api.get('/truck/tvm_health/'))
        .then(res => {
            return res;
        });

    }
}
